import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { BarcodeFormat } from '@zxing/library';
import { LoaderService } from '../../providers/loader.service';
import validateAccessKey from 'br-validate-dfe-access-key';

@Component({
  selector: 'app-ler-barras',
  templateUrl: './ler-barras.component.html',
  styleUrls: ['./ler-barras.component.scss'],
})
export class LerBarrasComponent implements OnInit {
  scannerEnabled: any;
  codigoLido: any = "";
  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    //BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.EAN_8,
    //BarcodeFormat.CODE_39,
    //BarcodeFormat.CODE_93,
    BarcodeFormat.QR_CODE,
  ];
  constructor(public navParams: NavParams, 
    public alertCtrl: AlertController, public loader: LoaderService,
    private modalController: ModalController) { }

  ngOnInit() {  
    this.scannerEnabled = true;
  }

  public scanSuccessHandler(resultString: string) {
    this.scannerEnabled = false;
    this.codigoLido = resultString;    
    if(resultString.length == 44){
      let validar: boolean = validateAccessKey(resultString);
      if(validar == false){
        let texto = 'Chave de acesso inválida, tenta ler outra vez.';
        this.loader.showError("Ops.", texto);
      }
    }
  }

  public enableScanner() {
    this.scannerEnabled = !this.scannerEnabled;
        
  }
  voltar(){
    this.modalController.dismiss(this.codigoLido);
  }


}

<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button></ion-back-button>
    </ion-buttons>
    <ion-title>Escolher variações</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="lerEtiqueta()">
        <ion-icon name="camera"></ion-icon>        
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Escolher variações</ion-title>
    </ion-toolbar>    
  </ion-header>
  
  <ion-segment [(ngModel)]="filtroPesquisa">
    <ion-segment-button value="referencia">
      Ref
    </ion-segment-button>
    <ion-segment-button value="descricao">
      Descr
    </ion-segment-button>
    <ion-segment-button value="codigo_winsae">
      WinSAE
    </ion-segment-button>
    <ion-segment-button value="ean">
      EAN
    </ion-segment-button>
  </ion-segment>
  <ion-item>    
    <ion-input name="pesquisa" placeholder="Código do produto" #pesquisa (keyup)="valuechange($event)"  ng-reflect-placeholder="Pesquisar" [(ngModel)]="codigoProcurar"></ion-input>
    <ion-button (click)="pesquisarProduto()" slot="end">
      Buscar
    </ion-button>
  </ion-item>
  <div id="pwa">
    <input type="file" id="pwaphoto" #pwaphoto accept="image/x-png,image/jpeg" (change)="uploadPWA()">
  </div> 
  <hr>     
  <ion-list *ngFor="let item of obj">      
    <ion-item detail>
      <ion-checkbox (ionChange)="updateCucumber(item)" >
      </ion-checkbox>
      <ion-avatar item-start style="width: 120px !important;
      height: 120px !important;">
        <img *ngIf=" item.FOTO == null " src="assets/imgs/semImagem.jpg">
        <img *ngIf=" item.FOTO != null "
        style="border: 1px solid #d4d4d4;        
        width: 120px !important;
        height: 120px !important;
        border-radius: 1px !important;" src="{{ item.FOTO }}">
      </ion-avatar>
      <ion-label>
        <h3 class="ion-text-wrap" style="margin-top: 5px;">Código: <span>{{ item.CODPROD }} </span> | Ref.: <span>{{ item.REFERENCIA }}</span></h3>      
        <h3 class="ion-text-wrap">Descr.: <span>{{ item.DESCRICAO }}</span></h3>
        <h3 class="ion-text-wrap">Marca: <span>{{ item.DESCRICAO_MARCA }}</span></h3>
        <h3 class="ion-text-wrap"> <strong>Cor: <span>{{ item.DESCRICAO_COR }}</span></strong></h3>
        <h3 class="ion-text-wrap">Preço: <span>R$ {{ item.VENDA }}</span></h3>        
      </ion-label>
    </ion-item>
  </ion-list>  
  <ion-button color="warning" expand="full" (click)="voltar()">Escolher variações</ion-button>
  <div *ngIf="loading == false">
    <div class="ion-padding custom-skeleton">
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
      <ion-skeleton-text animated></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 88%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 70%"></ion-skeleton-text>
      <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
    </div>
  
    <ion-list>
      <ion-list-header>
        <ion-label>
          <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
        </ion-label>
      </ion-list-header>
      <ion-item>
        <ion-avatar slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-avatar>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-thumbnail slot="start">
          <ion-skeleton-text animated></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
      <ion-item>
        <ion-skeleton-text animated style="width: 27px; height: 27px" slot="start"></ion-skeleton-text>
        <ion-label>
          <h3>
            <ion-skeleton-text animated style="width: 50%"></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-list>
  </div>

  
</ion-content>
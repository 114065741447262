import { Injectable } from '@angular/core';
import { AlertController, LoadingController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(public loadingController: LoadingController, public alertCtrl: AlertController,
    public toastController: ToastController) { }

  showError(titulo, text) {    
    this.alertCtrl.create({
      // title: 'Falha',
      header: titulo,      
      message: text,
      buttons: ['OK']
    }).then((res) => {
      res.present();
    });    
  }

  showLoader(texto) {
    this.loadingController.create({
      message: texto,
      cssClass:'custom-loader-class',
      showBackdrop: true,
      spinner: 'circular',
      duration: 15000
    }).then((res) => {
      res.present();
    });

  }

  presentToast(texto) {
    this.toastController.create({
      message: texto, //'Your settings have been saved.',
      duration: 4000,
      position: 'bottom',
      color: 'danger'      
    }).then((res) => {
      res.present();
    });
  }

  // Hide the loader if already created otherwise return error
  hideLoader() {
    this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
    }).catch((error) => {
      console.log('error', error);
    });
  }

  

}

import { Component, OnInit } from '@angular/core';
import { AlertController, LoadingController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { EstoqueLojasService } from '../../providers/estoque-lojas.service';
import { format, parseISO } from 'date-fns';



@Component({
  selector: 'app-parametros',
  templateUrl: './parametros.component.html',
  styleUrls: ['./parametros.component.scss'],
})
export class ParametrosComponent implements OnInit {
  arrayArtigo = [];
  modalTitle: string;
  modelId: number;
  arrayLojas: any = [];
  lojaEscolhida: any = "";
  lojas: boolean = false;
  artigo: boolean = false;
  grupo: boolean = false;
  marca: boolean = false;
  fornecedor: boolean = false;
  dataInicial: boolean = false;
  dataFinal: boolean = false;
  data: boolean = false;
  genero: boolean = false;
  colecao: boolean = false;
  parametro_retroativo: boolean = false;
  somenteValores: boolean = false;
  dataMes: boolean = false;
  data1: boolean = false;
  data2: boolean = false;

  artigos: any;
  marcas: any;
  grupos: any;  

  valoresVencimento: boolean = false;
  
  quantidade: any;
  parametro = {
    lj : "",
    dataInicial: "",
    dataFinal: "",
    dataMes: "",
    parametro: "",
    data1: "",
    data2: "",
    somenteValores: false,  
    filtroArtigos : [],   
    filtroMarcas: [],
    filtroGrupos: [],  
    valoresVencimento: false
  };
  constructor(private modalController: ModalController,
    private navParams: NavParams, public estoqueLojas: EstoqueLojasService, 
    public alertCtrl: AlertController,
    public popoverController: PopoverController,
    public loadingController: LoadingController) { }

  ngOnInit() {    
    this.lojas = this.navParams.data.lojas;
    this.artigo = this.navParams.data.artigo;
    this.grupo = this.navParams.data.grupo;
    this.marca = this.navParams.data.marca;
    this.fornecedor = this.navParams.data.fornecedor;
    this.dataInicial = this.navParams.data.dataInicial;
    this.dataFinal = this.navParams.data.dataFinal;
    this.data = this.navParams.data.data;
    this.dataMes = this.navParams.data.dataMes;
    this.genero = this.navParams.data.genero;
    this.colecao = this.navParams.data.colecao;   
    if(this.artigo == true){
      this.showLoader('Buscando informações no servidor.');
      this.buscarArtigosServidor();
    }
  }

  async closeModal() {
    this.parametro.dataInicial = this.parametro.dataInicial.length > 10 ? this.parametro.dataInicial.substring(0,10) : this.parametro.dataInicial;
    this.parametro.dataFinal = this.parametro.dataFinal.length > 10 ? this.parametro.dataFinal.substring(0,10) : this.parametro.dataFinal;
    this.parametro.dataMes = this.parametro.dataMes.length > 10 ? this.parametro.dataMes.substring(0,7) : this.parametro.dataMes;    
    await this.modalController.dismiss(this.parametro);
  }

  showLoader(texto) {
    this.loadingController.create({
      message: texto,
      cssClass:'custom-loader-class',
      showBackdrop: true,
      spinner: 'circular',
      duration: 15000
    }).then((res) => {
      res.present();
    });

  }
  
  hideLoader() {
    this.loadingController.dismiss().then((res) => {
      console.log('Loading dismissed!', res);
    }).catch((error) => {
      console.log('error', error);
    });
  }

  buscarArtigosServidor(){
    this.artigos = [];
    
    this.estoqueLojas.buscarArtigo()
    .then((result: any) => {
        this.hideLoader();
        this.artigos = result;
      })
      .catch((error: any) => {
        this.hideLoader();
        console.log('error: '+error);
      });
  }

  formatDate(value: string, formato: string) {
    return format(parseISO(value), formato/*'yyyy-MM-dd'*/);
  }

  mesAno(e){
    this.parametro.dataMes = this.formatDate(e, 'yyyy-MM');
    this.popoverController.dismiss();
  }

  escolherData(e){
    //this.data = this.formatDate(e, 'yyyy-MM-dd');
    this.popoverController.dismiss();
  }

  escolherDataInicial(e){
    this.parametro.dataInicial = this.formatDate(e, 'yyyy-MM-dd');
    this.popoverController.dismiss();
  }

  escolherDataFinal(e){
    this.parametro.dataFinal = this.formatDate(e, 'yyyy-MM-dd');
    this.popoverController.dismiss();
  }

  escolherData1(e){
    this.parametro.data1 = this.formatDate(e, 'yyyy-MM-dd');
    this.popoverController.dismiss();
  }

  escolherData2(e){
    this.parametro.data2 = this.formatDate(e, 'yyyy-MM-dd');
    this.popoverController.dismiss();
  }

  buscarMarcasServidor(){
    this.marcas = [];
    this.showLoader('Buscando informações no servidor.');
    this.estoqueLojas.buscarMarcas(this.parametro.filtroArtigos)
    .then((result: any) => {
        this.marcas = result;
        this.hideLoader();
        this.buscarMarcas();
      })
      .catch((error: any) => {
        this.hideLoader()
        console.log('error: '+error);
      });
  }

  buscarGruposServidor(){
    this.grupos = [];
    this.showLoader('Buscando informações no servidor.');
    this.estoqueLojas.buscarGrupos(this.parametro.filtroArtigos, this.parametro.filtroMarcas)
    .then((result: any) => {
        this.hideLoader();
        this.grupos = result;
        this.buscarGrupos();
      })
      .catch((error: any) => {
        this.hideLoader();
        console.log('error: '+error);
      });
  }

  async buscarArtigos(){
    this.arrayArtigo = [];
    for (let i = 0; i < this.artigos.length; i++) {
      const element = this.artigos[i];
      this.arrayArtigo.push({
        type: 'checkbox',
        label: element['DESCR'],
        value: element['COD_ARTIGO'],
      });      
    }
    let alert = await this.alertCtrl.create({      
      header: 'Atenção',
      message: 'Escolha os artigos ou deixe em branco.',
      inputs: this.arrayArtigo,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: (data) => {            
            this.parametro.filtroArtigos = data;
          }
        }
      ]
    });
    await alert.present();
  }

  async buscarMarcas(){
    let arrayMarcas = [];
    for (let i = 0; i < this.marcas.length; i++) {
      const element = this.marcas[i];
      arrayMarcas.push({
        type: 'checkbox',
        label: element['DESCR'],
        value: element['COD_MARCA'],
      });
    }
    let alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Escolha os marcas ou deixe em branco.',
      inputs: arrayMarcas,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            this.parametro.filtroMarcas = data;
          }
        }
      ]
    });    
    alert.present();
  }

  async buscarGrupos(){
    let arrayGrupos = [];
    for (let i = 0; i < this.grupos.length; i++) {
      const element = this.grupos[i];
      arrayGrupos.push({
        type: 'checkbox',
        label: element['TITULO'],
        value: element['GRUPO'],
      });
    }
    let alert = await this.alertCtrl.create({
      header: 'Atenção',
      message: 'Escolha os grupos ou deixe em branco.',
      inputs: arrayGrupos,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Ok',
          handler: (data) => {
            this.parametro.filtroGrupos = data;
          }
        }
      ]
    });        
    alert.present();
  }
  
  async buscarLojas() {
    this.showLoader('Buscando informações no servidor.');
    this.estoqueLojas.buscarLojas()
    .then((result: any) => {      
      this.hideLoader();
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.arrayLojas.push({
          name: element.CODLOJA,
          type: 'radio',          
          label: element.CODLOJA+" - "+element.DESCLOJA,
          value: element.CODLOJA          
        });                
      }
      if(this.arrayLojas.length > 0){
        this.escolherLojas();
      }
    })
    .catch((error: any) => {
      this.hideLoader();
      console.log('error: '+error);
    });
  }

  async escolherLojas(){
    const alert = await this.alertCtrl.create({
      header: 'Lojas',
      inputs: this.arrayLojas,
      buttons: [
        {
          text: 'Cancelar',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('Confirm Cancel');
          }
        }, {
          text: 'Escolher',
          handler: (data) => {          
            this.parametro.lj = data;            
          }
        }
      ]
    });
    await alert.present();
  }

}

<ion-app [class.dark-theme]="dark">
  <ion-split-pane contentId="main-content">

    <ion-menu contentId="main-content">
      <ion-content>
      
        <ion-list *ngIf="loggedIn" lines="none">
          <ion-list-header>
            Conta
          </ion-list-header>
          
          <div class="centralizar">            
            <img *ngIf=" foto != null || foto != '' " src="{{ foto }}">
            <img *ngIf=" foto == null || foto == '' " src="assets/imgs/semImagem.jpg">
          </div>                                            
          
          <ion-menu-toggle autoHide="false">
            <ion-item color="warning" detail="false">
              <ion-icon slot="start" name="person"></ion-icon>
              <ion-label>                
                {{ nome }}
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item (click)="suporte()" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Ajuda
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item (click)="novidades()" detail="false">              
              <ion-icon slot="start" name="newspaper-outline"></ion-icon>
              <ion-label>
                Novidades
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item (click)="atualizar()" detail="false">              
              <ion-icon slot="start" name="refresh-outline"></ion-icon>
              <ion-label>
                Atualizar
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item button (click)="logout()" detail="false">
              <ion-icon slot="start" name="log-out"></ion-icon>
              <ion-label>
                Logout
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle autoHide="false">
            <ion-item>
              <ion-icon slot="start" name="moon-outline"></ion-icon>
              <ion-label>
                Fundo escuro
              </ion-label>
              <ion-toggle [(ngModel)]="dark" (ionChange)="statusDark()"></ion-toggle>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>

        <ion-list *ngIf="!loggedIn" lines="none">
          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/login" routerLinkActive="active" routerDirection="root" detail="false">
              <ion-icon slot="start" name="log-in"></ion-icon>
              <ion-label>
                Login
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item routerLink="/support" routerLinkActive="active" routerDirection="root" detail="false">              
              <ion-icon slot="start" name="server-outline"></ion-icon>
              <ion-label>
                Cadastrar servidor
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item (click)="suporte()" detail="false">
              <ion-icon slot="start" name="help"></ion-icon>
              <ion-label>
                Ajuda
              </ion-label>
            </ion-item>
          </ion-menu-toggle>

          <ion-menu-toggle autoHide="false">
            <ion-item (click)="atualizar()" detail="false">              
              <ion-icon slot="start" name="refresh-outline"></ion-icon>
              <ion-label>
                Atualizar
              </ion-label>
            </ion-item>
          </ion-menu-toggle>
          
          <ion-menu-toggle autoHide="false">
            <ion-item>
              <ion-icon slot="start" name="moon-outline"></ion-icon>
              <ion-label>
                Fundo escuro
              </ion-label>
              <ion-toggle [(ngModel)]="dark" (ionBlur)="statusDark()"></ion-toggle>
            </ion-item>
          </ion-menu-toggle>
          
        </ion-list>

        
      </ion-content>
    </ion-menu>    
    <ion-router-outlet id="main-content"></ion-router-outlet>

  </ion-split-pane>

</ion-app>

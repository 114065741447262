import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class VendedoresLojasService {

  parametro = {
    "id" : "",
    "loja": ""
  }

  constructor(public auth: AuthServiceService, private http: HttpClient) { }

  pesquisarVendedores(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      return new Promise((resolve, reject) => {        
        this.http.post(this.auth.URL + '/consultarVendedores', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  pesquisarVendedoresPorLoja(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.parametro.id = this.auth.Id;
      this.parametro.loja = parametro;
      return new Promise((resolve, reject) => {        
        this.http.post(this.auth.URL + '/consultarVendedoresPorLoja', this.parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  atualizarLojaVendedores(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/atualizarLojaVendedores', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  pesquisarListaDeVezVendedores(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/listaDeVezVendedores', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  insertListaDeVezVendedores(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.parametro.id = this.auth.Id;
      let ar  = [];
      ar.push(this.parametro);
      ar.push(parametro);
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/insertListaDeVezVendedores', ar)
        .subscribe((result: any) => {          
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  avaliacaoAtendimento(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/avaliacaoAtendimento', parametro)
        .subscribe((result: any) => {          
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  avaliacaoAtendimentoDetalhado(parametro) {
    if(this.auth.URL == '' || this.auth.Id == undefined) {
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/avaliacaoAtendimentoDetalhada', parametro)
        .subscribe((result: any) => {          
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }
}

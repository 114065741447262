import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {

  public ano = (new Date().getFullYear() + 2).toString();
  public celular: boolean = false;
  public modeloCelular: string = "";
  public tokenFCM: any;
  public device: any;
  public logado: boolean = false;
  public shopee: boolean = false;
  public mercadoLivre: boolean = false;
  public nuvemShop: boolean = false;
  public woocomerce: boolean = false;
  public pluggto: boolean = false;
  public URL = 'https://winsae.com.br/tecnodata/index.php/api/Rest'; // (PRINCIPAL)
  public URLimpressora = 'http://127.0.0.1:3001/print'; // (PRINCIPAL)
  
  public Id: any = '' ;
  public fotoLoja: any;
  
  public Usuario = {
    codigo: '',
    descvenda: 0,
    gerente: '',
    loja: '',
    nome: '',
    status: '',
    vendedor: '',
    ativoSistema: '',
    ativoWhatsapp: '',
    CNPJ: '',
    somenteLojaLocal: false
  }

  constructor(private http: HttpClient) { 

  }

  arquivoAdquirente(credentials) {
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/arquivoAdquirente', credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  remessa(credentials) {
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/remessa', credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  print(credentials) {
    return new Promise((resolve, reject) => {
      this.http.post(this.URLimpressora, credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  login(credentials) {
    this.Id = credentials['id'];
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/loginp', credentials)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarServidor(cnpj){    
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/consultarServidor', cnpj)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  consultarPermissoesUsuarios(parametro){    
    return new Promise((resolve, reject) => {
      this.http.post(this.URL + '/consultarPermissoesUsuarios', parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
    });
  }

  loginCredencial(credentials) {
    if(this.URL == ''){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.URL + '/credenciarDescontop', credentials)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  consultarTodosUsuarios(credentials) {
    if(this.URL == ''){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.URL + '/consultarUsuarios', credentials)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarCredenciaisPIX(credentials) {
    if(this.URL == ''){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.URL + '/buscarCredenciaisPIX', credentials)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class EstoqueLojasService {
  public Parametro = {
    idProdutoPai: '',
    idConexao: '',
    id: '',
    mesAno: '',
    loja: '',
    data: '',
    data_inicial: '',
    data_final: '',
    artigos: '',
    marcas: [],
    filtroArtigos: [],
    filtroMarcas: [],
    filtroGrupos: [],
    filtroFornecedores: [],
    codigo: '',
    parametro: '',
    codclie: '',
    referencia: '',
    nf: '',
    pedido: '',
    ncm: '',    
    codforn: '',
    valor: '',
    chaveNFe: '',
    descricao: '',
    descricaoCompleta: '',
    idPai: '',
    body: '',
    woocommerce: false,
    mercadolivre: false, 
    nuvemshop: false,
    shopee: false,
    chave: '',
    atributos: '',
    comprimento: '',
    largura: '',
    altura: ''
  }
  constructor(public auth: AuthServiceService, private http: HttpClient) { }

  detalheVendasPorArtigos(credentials) {
    this.Parametro.id = this.auth.Id;  
    if(this.auth.Id == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      this.Parametro.data_inicial = credentials['dataInicial'];
      this.Parametro.data_final = credentials['dataFinal'];          
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/curvaABCArtigo', this.Parametro)
        .subscribe((result: any) => {           
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  detalheVendasPorMarcas(credentials) {
    this.Parametro.id = this.auth.Id; 
    if(this.auth.Id == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      this.Parametro.data_inicial = credentials['dataInicial'];
      this.Parametro.data_final = credentials['dataFinal'];           
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/curvaABCMarca', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  detalheVendasPorGrupos(credentials) {
    if(this.auth.Id == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      this.Parametro.id = this.auth.Id;      
      this.Parametro.data_inicial = credentials['dataInicial'];
      this.Parametro.data_final = credentials['dataFinal'];    
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/curvaABCGrupo', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  detalheVendasPorTamanhos(credentials) {
    if(this.auth.Id == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      this.Parametro.id = this.auth.Id;      
      this.Parametro.data_inicial = credentials['dataInicial'];
      this.Parametro.data_final = credentials['dataFinal'];    
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/detalheDesempenhoDeClientesTamanho', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarLojas(){
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      if(this.auth.Usuario.somenteLojaLocal == true){
        this.Parametro.loja = this.auth.Usuario.loja;      
      } 
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarLojasp', this.Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarFornecedores(param){
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id; 
      this.Parametro.parametro = param;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarFornecedorp', this.Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  inserirFornecedores(param){
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/inserirFornecedor', param)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarParametroEmpresa(parametro){
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarParametroEmpresa', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
      });
    }
  }
  
  buscarArtigo() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarArtigop', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarTodosArtigo() {
    this.Parametro.id = this.auth.Id;
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarTodosArtigos', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarMarcas(artigos) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      this.Parametro.artigos = artigos;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarMarcap', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarTodasMarcas() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarTodasMarcas', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarGrupos(artigos, marcas) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      this.Parametro.artigos = artigos;
      this.Parametro.marcas = marcas;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarGrupop', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarTodosGrupos() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarTodosGrupos', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarColecoes() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarColecaop', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarTodasColecoes() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarTodasColecoes', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }

  buscarTodasCores() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarTodasCores', this.Parametro)
          .subscribe((result: any) => {
            resolve(result);
          },
          (error) => {
            reject(error);
          });
      });
    }
  }
  
  buscarEstoqueResumido() {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarResumoDeEstoquep', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarEstoqueResumidoFiltro(parametro) {
    // this.Parametro.id = this.auth.Id;
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      parametro['idConexao'] = this.auth.Id;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarResumoDeEstoqueDetalhadop', parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  inserirFotoAleatoria(Parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirFotoAleatoria', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirFotoAleatoriaNovo(Parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirFotoAleatoriaNovo', Parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarEstoquep(parametro) {
    // this.Parametro.id = this.auth.Id;
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      this.Parametro.parametro = parametro['parametro'];
      this.Parametro.codigo = parametro['codigo'];      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarEstoquep', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarEstoqueTotalPorVariacoes(parametro) {
    // this.Parametro.id = this.auth.Id;
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {
      this.Parametro.id = this.auth.Id;
      this.Parametro.parametro = parametro['parametro'];
      this.Parametro.codigo = parametro['codigo'];
      this.Parametro.filtroArtigos = parametro['filtroArtigos'];
      this.Parametro.filtroMarcas = parametro['filtroMarcas'];
      this.Parametro.filtroGrupos = parametro['filtroGrupos'];
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarEstoqueTotalPorVariacoes', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  atualizarCadastroPromocao(parametro) {
    parametro['id'] = this.auth.Id;
    parametro['usuario'] = this.auth.Usuario.codigo;    
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/atualizarProdutos', parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarEntradas(parametro) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.data_inicial = parametro['data_inicial'];
    this.Parametro.data_final = parametro['data_final'];   
    this.Parametro.referencia = parametro['referencia'];
    this.Parametro.nf = parametro['nf'];
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/entradasEstoque', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  downloadNF(parametro) {
    this.Parametro.id = this.auth.Id;    
    this.Parametro.chave = parametro['chave'];
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/imprimirDanfe', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }


  buscarEntradasPedido(parametro) {
    this.Parametro.id = this.auth.Id;    
    this.Parametro.pedido = parametro['pedido'];
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/entradasEstoquePedido', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  estoquePorGrupo(parametro) {    
    this.Parametro.id = this.auth.Id;    
    this.Parametro.loja = parametro['loja'];
    this.Parametro.parametro = parametro;    
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {           
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/estoquePorGrupo', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  consultarProduto(parametro) {    
    this.Parametro.id = this.auth.Id;        
    this.Parametro.parametro = parametro;
    // console.log(this.Parametro);
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarProdutos', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  inserirPromocaoProduto(parametro) {    
    this.Parametro.id = this.auth.Id;        
    this.Parametro.parametro = parametro;
    this.Parametro.codigo = this.auth.Usuario.codigo;
    if(this.auth.URL == '' || this.auth.Id == undefined || this.auth.Usuario.codigo == ''){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/inserirPromocaoProdutos', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarTamanho() {    
    this.Parametro.id = this.auth.Id;            
    if(this.auth.URL == '' || this.auth.Id == undefined || this.auth.Usuario.codigo == ''){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarTamanhos', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarCadastroTamanho() {    
    this.Parametro.id = this.auth.Id;
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarCadastroTamanhos', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarNCM(ncm) {
    this.Parametro.id = this.auth.Id;  
    this.Parametro.ncm = ncm;          
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarNCM', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarReferenciaFornecedor(referencia, codforn) {
    this.Parametro.id = this.auth.Id;  
    this.Parametro.referencia = referencia;
    this.Parametro.codforn = codforn;    
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/produtoPorReferencia', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  cadastrarProduto(param) {
    this.Parametro.id = this.auth.Id;      
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/cadastrarProduto', param)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  cadastrarAleatorio(parametro, valor) {
    this.Parametro.id = this.auth.Id;  
    this.Parametro.parametro = parametro;
    this.Parametro.valor = valor;
    console.log(this.Parametro);
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/cadastroAleatorio', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  movimentacaoEstoque(param, loja) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.codigo = param;
    this.Parametro.loja = loja;
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/historicoDeProduto', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  vendasGruposPorFornecedor(param) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.codigo = param;
    this.Parametro.loja = param.loja;
    this.Parametro.data_inicial = param.data_inicial;
    this.Parametro.data_final = param.data_final;
    this.Parametro.filtroFornecedores = param.filtroFornecedores;    
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {         
      // console.log(this.Parametro);   
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/produtosMaisVendidosPorFornecedor', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  consultarNFeAMC(param) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.chaveNFe = param;  
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {         
      // console.log(this.Parametro);   
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultarNotasAMC', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  cadastrarItemAMC(param, artigo) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.referencia = param;
    this.Parametro.artigos = artigo;
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {         
      // console.log(this.Parametro);   
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/cadastrarProdutosAMC', this.Parametro)
        .subscribe((result: any) => {
          // console.log(result);
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  consultarItemReferenciaAMC(param) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.referencia = param;  
    if(this.auth.URL == '' ){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);      
    } else {         
      // console.log(this.Parametro);   
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultarProdutosAMC', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  inserirEcommercePai(param, descricaoCompleta, sku, atributos, comprimento, largura, altura) {
    this.Parametro.id = this.auth.Id;
    this.Parametro.descricao = param;
    this.Parametro.descricaoCompleta = descricaoCompleta;
    this.Parametro.codigo = sku;
    this.Parametro.atributos = atributos;
    this.Parametro.comprimento = comprimento;
    this.Parametro.largura = largura;
    this.Parametro.altura = altura;
    if(this.auth.URL == '' ){      
    } else {               
      // console.log(this.Parametro);
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/inserirEcommercePAI', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarFotoEcommercePai(idPai) {
    this.Parametro.id = this.auth.Id;      
    this.Parametro.idPai = idPai;
    if(this.auth.URL == '' ){      
    } else {               
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarFotoEcommerceNew', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  addMercadoLivreNew(body) {
    this.Parametro.id = this.auth.Id;      
    this.Parametro.body = body;
    if(this.auth.URL == '' ){      
    } else {               
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/addMercadoLivreNew', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarProdutoNovoEcommerce(idPai) {
    this.Parametro.id = this.auth.Id;      
    this.Parametro.idPai = idPai;
    if(this.auth.URL == '' ){      
    } else {               
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarProdutoNovoEcommerce', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  inserirEcommerceProd01(idPai, codprod, valor, item) {
    this.Parametro.id = this.auth.Id;      
    this.Parametro.idPai = idPai;
    this.Parametro.codigo = item;
    
    this.Parametro.nuvemshop = this.auth.nuvemShop;
    this.Parametro.mercadolivre = this.auth.mercadoLivre;
    this.Parametro.shopee = this.auth.shopee;
    this.Parametro.woocommerce = this.auth.woocomerce;

    this.Parametro.valor = valor;
    if(this.auth.URL == '' ){      
    } else {
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/inserirEcommerceProd01', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);          
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  buscarGrupoCatalogo(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarGrupoCatalogo', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  buscarVariacoes(parametro) {
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/buscarVariacoesEcommerce', parametro)
        .subscribe((result: any) => {
          if(result._body != ""){
            resolve(result);
          }
        },
        (error) => {
          reject(error);
        });
    });
  }

  inserirItemEcommerceNovo(parametro) {
    this.Parametro.idConexao = this.auth.Id;      
    this.Parametro.idProdutoPai = parametro;
    // console.log(this.Parametro)
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/inserirItemEcommerceNovo', this.Parametro)
        .subscribe((result: any) => {
          // console.log(result);
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        });
    });
  }

  consultarItemEcommerceNovo(parametro) {
    this.Parametro.idConexao = this.auth.Id;      
    this.Parametro.idProdutoPai = parametro;
    // console.log(this.Parametro)
    return new Promise((resolve, reject) => {
      this.http.post(this.auth.URL + '/consultarItemEcommerceNovo', this.Parametro)
        .subscribe((result: any) => {
          // console.log(result);
          resolve(result);
        },
        (error) => {
          console.log(error);
          reject(error);
        });
    });
  }
  
}

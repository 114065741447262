import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { Storage } from '@ionic/storage';
import { AuthServiceService } from './auth-service.service';
import { UserData } from './user-data';
@Injectable({
  providedIn: 'root'
})
export class CheckTutorial implements CanLoad, CanActivate {
  constructor(private storage: Storage, private router: Router, private auth: AuthServiceService, 
    public userData: UserData) {}

  canLoad() {
    return this.storage.get('ID').then(val => {
      if(val != null){
        for (let i = 0; i < val.length; i++) {
          const element = val[i];
          // console.log(element);
          this.auth.Id = element.ID;
          for (let ab = 0; ab < element.PERMISSOES.length; ab++) {
            const elemento = element.PERMISSOES[ab];
            if(elemento.MODULO == "ResumodoDiaPorOperador" && elemento.ENTRAR == "true"){
              // console.log(elemento);
              this.auth.Usuario.somenteLojaLocal = true;
            }            
            if(elemento.MODULO == "ResumodoDiaPorOperador" && elemento.ENTRAR == "false"){
              // console.log(elemento);
              this.auth.Usuario.somenteLojaLocal = false;
            }            
          }
        }
      }
      
      return true
      /*if (res) {
        this.router.navigate(['/app', 'tabs', 'schedule']);
        return false;
      } else {
        return true;
      }*/
    });
  }  

  canActivate(){
    // console.log('canActivate');
    this.validarDias();
    return true;
  }

  validarDias(){
    const date1 = new Date();
    return this.userData.getDataExpira().then(dataExpira => {            
      let date2 = new Date(dataExpira); 
      // console.log(date1.getTime() +"-"+ date2.getTime())     
      if(date1.getTime() > date2.getTime()){        
        this.userData.logout().then(() => {
          return this.router.navigateByUrl('/login');
        });
      }      
    });
  }  
}

<ion-header>
  <ion-toolbar color="danger">
    <ion-title>Conta corrente</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content padding>
  <ion-list>
    <ion-item *ngFor="let item of extrato" (click)="confirmarPagamento(item)">
      <ion-avatar item-start>
        <img src="{{ item.BANCO_ORIGEM }}">
      </ion-avatar>
      <ion-label>
        <h2>Agência: {{ item.AGEN }} - Conta: {{ item.CONTA_CC }}</h2>
        <!--<p>Saldo: {{ item.SALDO | formatarDinheiro }}</p>-->
        <p>Loja: {{ item.LOJA }}</p>
      </ion-label>
    </ion-item>
  </ion-list>    
</ion-content>

<ion-footer>
  <ion-toolbar>
    <ion-button expand="full" (click)="dismiss()">Cancelar</ion-button>
  </ion-toolbar>
</ion-footer>
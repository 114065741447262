import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-tela-mercadolivre',
  templateUrl: './tela-mercadolivre.component.html',
  styleUrls: ['./tela-mercadolivre.component.scss'],
})
export class TelaMercadolivreComponent implements OnInit {
  categorias: any;
  descricao: any = "";
  atribut: any = [];
  default_unit: any;
  parametro: any = "";
  nomeParametro: any = "";
  place: any = "";
  id: any = "";
  val: any = "";
  oque: any = "";
  constructor(private navParams: NavParams, public modalController: ModalController) {
    this.atribut = [];
    this.parametro = this.navParams.data.parametro;    
    // console.log(this.parametro);
    if(this.navParams.data.parametro == "atributos"){
      // console.log(this.navParams.data);
      this.oque = this.navParams.data.categorias.id;
      if(this.navParams.data.categorias.values){
        let cata = this.navParams.data.categorias.values;        
        this.atribut = [];
          for (let i = 0; i < cata.length; i++) {     
            const element = cata[i];
            // console.log(element);
            this.atribut.push(element);
          }
      }      
      this.nomeParametro = this.navParams.data.categorias.name;
      this.place = this.navParams.data.categorias.hint;      
      this.id = this.navParams.data.categorias.id;
      this.default_unit = (this.navParams.data.categorias.default_unit == '' || this.navParams.data.categorias.default_unit == 'undefined' || this.navParams.data.categorias.default_unit == undefined) ? '' : 'Em ('+this.navParams.data.categorias.default_unit+')';
    } else if(this.navParams.data.parametro == "descricao"){
      this.nomeParametro = "Título do produto";
      this.place = "Crie o título com Produto + Marca + modelo do produto + algumas especificações que ajudem a identificar o produto.";
    } else {
      let cat = this.navParams.data.categorias;      
      this.categorias = [];
      for (let i = 0; i < cat.length; i++) {        
        // console.log(i);
        const element = cat[i];
        this.categorias.push(element);
      }
    }
  }

  ngOnInit() {}

  async voltar() {
    await this.modalController.dismiss();
  }

  async escolherCategoria(categoria) {
    await this.modalController.dismiss({
      "valorEscolhido" : categoria, 
      "parametro": this.parametro
    });
  }

  async escolherAtributo(categoria) {
    // {id: "5084828", value_name: "Chimmy Churry"}
    console.log(categoria);
    await this.modalController.dismiss({
      "valorEscolhido" : {
        "id": this.oque,
        "name": categoria.name,
        "value_name": categoria.name,
        "value_id": categoria.id
      }, 
      "parametro": this.parametro
    });
  }

  async confirmarEscolhe(){
    
    await this.modalController.dismiss({
      "valorEscolhido" : {
        "id": this.oque,
        "name": this.val,
        "value_name": this.val,
        "value_id": this.id
      }, 
      "parametro": this.parametro
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { AlertController, ModalController } from '@ionic/angular';
import { AuthServiceService } from '../../providers/auth-service.service';
import { EstoqueLojasService } from '../../providers/estoque-lojas.service';
import { LoaderService } from '../../providers/loader.service';
import { LerBarrasComponent } from '../ler-barras/ler-barras.component';

@Component({
  selector: 'app-pesquisar-produtos',
  templateUrl: './pesquisar-produtos.component.html',
  styleUrls: ['./pesquisar-produtos.component.scss'],
})
export class PesquisarProdutosComponent implements OnInit {
  web : boolean = true;
  public obj: any;
  public Parametro = {
    id: '',
    codigo: '',
    parametro: ''
  }
  arr: any = [];
  items: any;
  // hoje: any = '2021-06-16';
  mes: any;
  hoje: any;
  dia: any;
  cadastro = {
    codprod: '',
    descr: '',
    refe: '',
    custo: '',
    venda: '',
    iniprom: '',
    fimprom: '',
    promocao: '',
    nova_iniprom: '',
    nova_fimprom: '',
    nova_promocao: '',
    id: '',
    end_mac: '',
    usuario: ''
  }

  filtroPesquisa: any = 'referencia';
  codigoProcurar: any = '';
  loading: any;
  products: any[] = [];
  selectedProduct: any;
  productFound:boolean = false;
  baixaAutomatica: any = [];
  constructor(public auth: AuthServiceService, public loader: LoaderService,
    public estoque: EstoqueLojasService, public alertController: AlertController,
    private barcodeScanner: BarcodeScanner, 
    private route: ActivatedRoute, public modalController: ModalController) { 
      this.baixaAutomatica = [];
    }

  ngOnInit() {}

  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
  }

  updateCucumber(codprod) {
    let t: boolean = false;    
    let b = {
      //"CODPROD": codprod
      codprod
    }
    for (let i = 0; i < this.baixaAutomatica.length; i++) {
      const element = this.baixaAutomatica[i];
      if(element.CODPROD == codprod.CODPROD){
        t = true;        
        this.baixaAutomatica.splice(i, 1);
      } 
    }
    if(t == false){      
      this.baixaAutomatica.push(b);
    }    
  }

  valuechange(newValue) {
    if(newValue.key == 'Enter' || newValue.keyCode == 13 || newValue.keyIdentifier == 'Enter'){
      this.pesquisarProduto();
    }    
  }

  voltar(){
    this.modalController.dismiss(this.baixaAutomatica);
  }

  async lerBarras() {
    const modal = await this.modalController.create({
      component: LerBarrasComponent,
      componentProps: {        
      },
      backdropDismiss: false,      
    });

    modal.onDidDismiss().then((res) => {
      if(res.data != ''){
        if(res.data.length == 8){
          this.filtroPesquisa = 'codigo_winsae';
        } else {
          this.filtroPesquisa = 'ean';
        }
        this.codigoProcurar = res.data;
        this.pesquisarProduto();
      }
    });

    return await modal.present();
  }

  abrirCamera(){

  }
  
  uploadPWA(){

  }

  lerEtiqueta(){
    this.selectedProduct = {};
    this.barcodeScanner.scan().then((barcodeData) => {
      this.codigoProcurar = barcodeData.text;
      this.Parametro.codigo = this.codigoProcurar; 
      this.Parametro.parametro = this.filtroPesquisa;  
      if(barcodeData.text.length == 8){
        this.filtroPesquisa = 'codigo_winsae';
      } else {
        this.filtroPesquisa = 'ean';
      }      
      this.pesquisarProduto();
    }, (err) => {
      this.lerBarras();
    });
  }

  public pesquisarProduto(){
    this.arr = [];
    this.obj = [];
    this.items = [];    
    this.loading = false;
    if(this.codigoProcurar == ''){
      this.loader.showError('Ops', 'Deve preencher com algum valor.');
      this.loading = true;
    } else {
      this.Parametro.codigo = this.filtroPesquisa == 'codigo_winsae' ? this.padLeft(this.codigoProcurar, '0', 5) : this.codigoProcurar;
      this.Parametro.parametro = this.filtroPesquisa;    
      {
        this.estoque.buscarEstoquep(this.Parametro)
        .then((result: any) => {          
          this.loading = true;
          // console.log(result);
          let mapProdutos = result.map( function( elem ) {
            return {
              "CODPROD" : elem.CODPROD, 
              "CUSTO" : elem.CUSTO,
              "DESCRICAO" : elem.DESCRICAO,
              "FIMPROM" : elem.FIMPROM,
              "INIPROM" : elem.INIPROM,
              "PROMOCAO" : elem.PROMOCAO,
              "REFERENCIA" : elem.REFERENCIA,
              "VENDA" : elem.VENDA,
              "FOTO": elem.FOTO,
              "DESCRICAO_MARCA": elem.DESCRICAO_MARCA,
              "DESCRICAO_COR": elem.DESCRICAO_COR              
              };
          });
          
          mapProdutos.reduce((previous, current)=> {
            if(!previous[current['CODPROD']]) {
                previous[current['CODPROD']] = [current];
                this.arr.push(current);
            } else {                  
                console.log("Já existe");
            }  
            return previous;
          }, {});          
          this.obj = this.arr;             
          this.codigoProcurar = '';
          if(this.obj == ''){
            this.loader.showError('Ops', 'Não encontrei nada.');
          }
        })
        .catch((error: any) => {          
          //console.log('error: '+error);
          this.loading = true;
          this.loader.showError('Ops', 'O seu servidor pode esta sem internet no momento, tente novamente mais tarde.');
        });
      }
    }
  }

}

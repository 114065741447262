import { Component, OnInit, ViewChild } from '@angular/core';
import { IonInfiniteScroll, ModalController, NavParams } from '@ionic/angular';
import { EstoqueLojasService } from '../../providers/estoque-lojas.service';
import { LoaderService } from '../../providers/loader.service';

@Component({
  selector: 'app-tela-generica',
  templateUrl: './tela-generica.component.html',
  styleUrls: ['./tela-generica.component.scss'],
})
export class TelaGenericaComponent implements OnInit {
  @ViewChild(IonInfiniteScroll, { static: true }) infiniteScroll: IonInfiniteScroll;
  tela: any;
  codigoEscolhido: any;
  lista: any = [];
  codigos: any = [];
  coresEscolhidas: any = [];
  it;
  shouldShowCancel: boolean = false;
  cadastrar: boolean = false;
  searchbar: any = "";
  bloquearCampo: boolean = false;
  
  desabilitarBotao: boolean = false;
  param: any = "";

  constructor(public estoque: EstoqueLojasService, public loader: LoaderService, 
    public modalController: ModalController, private navParams: NavParams) { 
      this.tela = navParams.data.tela;    
      this.param = navParams.data.parametro;
      // console.log(navParams.data);
    }
  doInfinite(event) {
    //this.getEmployees(true, event);
    console.log(event);    
    event.target.complete();
  }
    
  ngOnInit() {
    if(this.tela != 'ncm'){
      // this.loader.showLoader("Consultando "+this.tela+"...");
    } 
    if(this.tela == 'artigo'){
      this.buscarArtigos();
    }
    if(this.tela == 'marca'){
      this.buscarMarcas();
    }
    if(this.tela == 'grupo'){
      this.buscarGrupos();
    }
    if(this.tela == 'colecao'){
      this.buscarColecoes();
    }
    if(this.tela == 'cores'){
      
      this.buscarCores();
    }
    if(this.tela == 'fornecedores'){
      this.buscarFornecedores();
    }
    if(this.tela == 'ncm'){
      this.loader.presentToast('Digite no mínimo 5 caracteres para começar a pesquisa do NCM!');
    }
  }

  buscarNCM(valor){
    this.loader.showLoader("Consultando "+this.tela+"...");
    this.estoque.buscarNCM(valor)
    .then((result: any) => {
      this.loader.hideLoader();            
      this.lista = [];
      this.codigos = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.codigos.push({
          "CODIGO" : element.NCM,
          "DESCRICAO" : element.DESCR
        });
      } 
      this.it = this.codigos;    
    })
    .catch((error: any) => {
      this.bloquearCampo = false;
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  initializeItems() {
    this.it = this.codigos;
  }

  getItems(ev) {
    this.initializeItems();
    var val = ev.target.value;
    if (val && val.trim() != '') {
      this.it = this.it.filter((item) => {
        return (item.DESCRICAO.toUpperCase().indexOf(val.toUpperCase()) > -1);
      })
      console.log(this.it.length + " - " + this.searchbar.length);
      if(this.it.length == 0 && this.searchbar != ""){
        this.cadastrar = true;
      } else {
        this.cadastrar = false;
      }
      if(val.length > 4 && this.tela == 'ncm'){
        this.bloquearCampo = true;
        this.buscarNCM(val);
      }      
    }
  }

  cadastrarGenerico(){
    this.desabilitarBotao = true;
    if(this.tela != "fornecedores"){
      this.estoque.cadastrarAleatorio(this.tela, this.searchbar)
      .then((result: any) => {
        if(this.tela == "cores"){
          this.coresEscolhidas.push({"CODIGO": result[0] ,"DESCRICAO": this.searchbar, "TELA": "cores"});
        } else {
          this.lista = {
            "CODIGO" : result[0],
            "DESCRICAO" : this.searchbar,
            "TELA": this.tela
          };
        }
        this.escolher();
        // console.log(result);
      })
      .catch((error: any) => {
        console.log('error: '+error);
      });
    } else {
      /*
      let profileModal = this.modalCtrl.create('CadastroContribuintePage', {"tela": "fornecedores"});
      profileModal.onDidDismiss(data => {        
        this.desabilitarBotao = false;
        this.searchbar = "";
        if(data != null){
          for (let i = 0; i < this.lista.length; i++) {
            const element = this.lista[i];
            if(element.CODIGO == data.codforn){              
              this.lista = {
                "CODIGO" : data.codforn,
                "DESCRICAO" : data.nome,
                "TELA" : this.tela
              };
              this.escolher();
            }
          }
        }
      });    
      profileModal.present();
      */
    }
  }

  buscarFornecedores(){
    this.loader.showLoader("Consultando fornecedores...");
    this.estoque.buscarFornecedores([])
    .then((result: any) => {      
        this.loader.hideLoader();
        this.lista = [];
        this.codigos = [];
        for (let i = 0; i < result.length; i++) {
          const element = result[i];
          let fant = (element.FANTASIA == '' || element.FANTASIA == null) ? element.DESCR : element.FANTASIA;
          let cnpj = (element.CGC == '' || element.CGC == null) ? '' : '-'+element.CGC;        
          if(this.param == 'cadastrarProduto'){
            element.SIGLA == 'F' ? this.codigos.push({
              "CODIGO" : element.CODFORN,
              "DESCRICAO" : fant+cnpj,
              //"FANTASIA": element.FANTASIA,
              //"CNPJ": element.CNPJ
            }) : '' ;
          } else {
            this.codigos.push({
              "CODIGO" : element.CODFORN,
              "DESCRICAO" : fant+cnpj,
              "TIPO" : element.SIGLA,
              //"FANTASIA": element.FANTASIA,
              //"CNPJ": element.CNPJ
            });
          }
        }
        this.it = this.codigos;
    })
    .catch((error: any) => {
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  buscarCores(){
    this.shouldShowCancel = true;
    this.loader.showLoader("Consultando cores...");
    this.estoque.buscarTodasCores()
    .then((result: any) => {    
      this.loader.hideLoader();
      this.lista = [];
      this.codigos = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.codigos.push({
          "CODIGO" : element.COD_COR,
          "DESCRICAO" : element.NOME_COR,
          "CHECK" : false
        });
      } 
      this.it = this.codigos;     
      // console.log(this.codigos);
    })
    .catch((error: any) => {
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  buscarColecoes(){
    this.loader.showLoader("Consultando coleções...");
    this.estoque.buscarTodasColecoes()
    .then((result: any) => {      
      this.loader.hideLoader();
      this.lista = [];
      this.codigos = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.codigos.push({
          "CODIGO" : element.ID,
          "DESCRICAO" : element.DESCR
        });
      } 
      this.it = this.codigos;     
      // console.log(this.codigos);
    })
    .catch((error: any) => {
      console.log('error: '+error);
    });
  }

  buscarArtigos(){    
    this.loader.showLoader("Consultando artigos...");
    this.estoque.buscarTodosArtigo()
    .then((result: any) => {   
      this.loader.hideLoader();
      this.lista = [];
      this.codigos = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.codigos.push({
          "CODIGO" : element.COD_ARTIGO,
          "DESCRICAO" : element.DESCR
        });        
      } 
      this.it = this.codigos;           
    })
    .catch((error: any) => {
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  buscarMarcas(){
    this.loader.showLoader("Consultando marcas...");
    this.estoque.buscarTodasMarcas()
    .then((result: any) => {   
      this.loader.hideLoader();
      this.lista = [];
      this.codigos = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.codigos.push({
          "CODIGO" : element.COD_MARCA,
          "DESCRICAO" : element.DESCR
        });        
      } 
      this.it = this.codigos;     
      // console.log(this.codigos);
    })
    .catch((error: any) => {
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  buscarGrupos(){
    this.loader.showLoader("Consultando grupos...");
    this.estoque.buscarTodosGrupos()
    .then((result: any) => {  
      this.loader.hideLoader();
      this.lista = [];
      this.codigos = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i];
        this.codigos.push({
          "CODIGO" : element.GRUPO,
          "DESCRICAO" : element.TITULO
        });
      } 
      this.it = this.codigos;     
      // console.log(this.codigos);
    })
    .catch((error: any) => {
      this.loader.hideLoader();
      console.log('error: '+error);
    });
  }

  marcarParametro(p){
    // this.codigoEscolhido = p;  
    // console.log(p);
    this.lista = {
      "CODIGO" : p.CODIGO,
      "DESCRICAO" : p.DESCRICAO,
      "TELA" : this.tela,
      "TIPO" : p.TIPO
    };
  }

  apagarCor(item){
    this.marcarCores(item);    
    this.it.push(item);
  }

  marcarCores(cor){
    let t: boolean = false;    
    let b = { "CODIGO": cor.CODIGO ,"DESCRICAO": cor.DESCRICAO, "TELA": "cores"}    
    for (let i = 0; i < this.coresEscolhidas.length; i++) {
      const element = this.coresEscolhidas[i];
      if(element.CODIGO == cor.CODIGO){
        t = true;        
        this.coresEscolhidas.splice(i, 1);
      } 
    }
    if(t == false){      
      this.coresEscolhidas.push(b);
      for (let a = 0; a < this.it.length; a++) {
        const element = this.it[a];
        if(element.CODIGO == cor.CODIGO){          
          this.it.splice(a, 1);
        } 
        
      }
    }
  }

  async escolher() {
    if(this.tela == "cores"){      
      await this.modalController.dismiss(this.coresEscolhidas);
      // this.viewCtrl.dismiss(this.coresEscolhidas);
    } else {
      await this.modalController.dismiss(this.lista);
      // this.viewCtrl.dismiss(this.lista);
    }
  }

}

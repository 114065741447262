import { Component, OnInit } from '@angular/core';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { AuthServiceService } from '../../providers/auth-service.service';
import { LoaderService } from '../../providers/loader.service';
import { VendedoresLojasService } from '../../providers/vendedores-lojas.service';

@Component({
  selector: 'app-avaliacao-detalhe',
  templateUrl: './avaliacao-detalhe.component.html',
  styleUrls: ['./avaliacao-detalhe.component.scss'],
})
export class AvaliacaoDetalheComponent implements OnInit {

  vendas: any = [];
  constructor(private modalController: ModalController,
    private navParams: NavParams, public alertCtrl: AlertController, public vendedores: VendedoresLojasService,
    public auth: AuthServiceService, public loader: LoaderService, public socialSharing: SocialSharing) {       
      this.consultarAvaliacao(this.navParams.data.avaliacao, this.navParams.data.vendedor)
    }

  ngOnInit() {}

  voltar(){
    this.modalController.dismiss();
  }

  chamarNoWhats(whats){
    this.socialSharing.shareViaWhatsAppToPhone('+55'+whats, 'O que houve com sua compra?', null, null).then((e) =>{
      console.log(e)
    }).catch((e) => {
      window.open("https://wa.me/55"+whats+"?text=O que houve com sua compra?", "_blank");
    });    
  }

  consultarAvaliacao(aval, vendedor){
    let ar = {
      "idConexao": this.auth.Id,
      "vendedor": vendedor
    }
    this.loader.showLoader('Consultando detalhe dos atendimentos');
    this.vendedores.avaliacaoAtendimentoDetalhado(ar)
    .then((result: any) => {
      // console.log(result);
      this.vendas = [];
      for (let i = 0; i < result.length; i++) {
        const element = result[i].DADOS;
        for (let a = 0; a < element.length; a++) {
          const k = element[a];
          let ab = k.VALOR.split('|');
          k.RETORNO = ab[1];
          k.VALOR = k.VALOR.split('|')[0];          
        }
      }
      // console.log(result);
      
      this.vendas = result;
      
      
      this.loader.hideLoader();
      if(result.length == 0){        
        this.loader.presentToast("Não encontrei nada!");        
      }      
      }).catch((error: any) => {
        this.loader.hideLoader();      
        this.loader.showError("Ops!", error);          
      });
  }

}

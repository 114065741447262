import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { IonicModule } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule } from '@angular/forms';
import { SQLite } from '@awesome-cordova-plugins/sqlite/ngx';
import { ParametrosComponent } from './modal/parametros/parametros.component';
import { TelaGenericaComponent } from './modal/tela-generica/tela-generica.component';
import { ContaCorrenteBaixaComponent } from './modal/conta-corrente-baixa/conta-corrente-baixa.component';
import { LerBarrasComponent } from './modal/ler-barras/ler-barras.component';
import { TelaMercadolivreComponent } from './modal/tela-mercadolivre/tela-mercadolivre.component';
import { PesquisarProdutosComponent } from './modal/pesquisar-produtos/pesquisar-produtos.component';
import { AvaliacaoDetalheComponent } from './modal/avaliacao-detalhe/avaliacao-detalhe.component';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    })
  ],
  declarations: [AppComponent, ParametrosComponent, TelaGenericaComponent, ContaCorrenteBaixaComponent, 
  LerBarrasComponent, TelaMercadolivreComponent, PesquisarProdutosComponent, AvaliacaoDetalheComponent],
  providers: [InAppBrowser, SQLite, SocialSharing, BarcodeScanner, Camera, File, FileOpener, Network
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}

<ion-header>
  <ion-toolbar>    
    <ion-title>ler Barras</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="enableScanner()">
        <ion-icon name="camera"></ion-icon>        
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-card>
    <ion-card-header>
      <ion-card-subtitle>Código de barras</ion-card-subtitle>
      <ion-card-title>{{ codigoLido }}</ion-card-title>
    </ion-card-header>
  
    <ion-card-content>
      <zxing-scanner [formats]="formatsEnabled" *ngIf="scannerEnabled" #scanner (scanSuccess)="scanSuccessHandler($event)">
      </zxing-scanner>      
    </ion-card-content>
  </ion-card>
  <ion-button (click)="voltar()" expand="full">Voltar</ion-button>
  
</ion-content>

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { AlertController, MenuController, Platform, ToastController } from '@ionic/angular';

import { StatusBar } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';

import { Network } from '@awesome-cordova-plugins/network/ngx';

import { Storage } from '@ionic/storage';

import { UserData } from './providers/user-data';
import { LoaderService } from './providers/loader.service';
import { DbService } from './providers/db.service';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  plat: any = '';
  appPages = [
    {
      title: 'Lojas',
      url: '/app/tabs/schedule',
      icon: 'home'
    },
    {
      title: 'Vendedores',
      url: '/app/tabs/speakers',
      icon: 'people'
    },
    {
      title: 'Estoque',
      url: '/app/tabs/map',
      icon: 'pricetags'
    },
    {
      title: 'Finanças',
      url: '/app/tabs/about',
      icon: 'cash'
    },
    {
      title: 'Clientes',
      url: '/app/tabs/clientes',
      icon: 'heart'
    }
  ];
  loggedIn = false;
  dark = false;
  nome = "";
  expira : any = "";
  foto: any = "";
  

  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private storage: Storage,
    private userData: UserData,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private alertController: AlertController,
    private loader: LoaderService,
    private db: DbService,
    private network: Network,
    private socialSharing: SocialSharing
  ) {
    this.initializeApp();
  }

  criarBD(){
    this.db.createDatabase().then((result: any) => {
      // alert(result);
    }).catch((err) => { 
      alert(err);
    });
  }

  atualizar(){
    if (this.swUpdate.isEnabled) { 
      this.swUpdate.checkForUpdate().then(() => { 
        this.loader.presentToast('Verificando atualizações...');
          this.updateToLatest();
      }).catch((err) => { 
        this.loader.presentToast('Erro ao verificar atualizações'); 
      }); 
    }
  }

  async novidades() {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Novidades',
      subHeader: 'O que tem nessa nova versão',
      message: '<strong>Lojas > Resumo diário > Detalhar: </strong>Possibilidade de detalhar as vendas do dia. <br><br>',
      buttons: ['OK']
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();
    console.log('onDidDismiss resolved with role', role);
  }


  updateToLatest():void {
    this.loader.presentToast('Atualizando para a versão mais recente. Refaça o login!'); 
    this.swUpdate.activateUpdate().then(() => document.location.reload()); 
  } 

  async ngOnInit() {    
    this.checkLoginStatus();
    this.listenForLoginEvents();
    this.returnName();
    this.returnFundoEscuro();
    this.returnDataExpira();
    // this.compararDias();
    this.swUpdate.available.subscribe(async res => {
      const toast = await this.toastCtrl.create({
        message: 'Atualização disponível!',
        position: 'bottom',
        buttons: [
          {
            role: 'Atualizar',
            text: 'Reload'
          }
        ]
      });

      await toast.present();

      toast
        .onDidDismiss()
        .then(() => this.swUpdate.activateUpdate())
        .then(() => window.location.reload());
    });
  }

  statusDark(){    
    this.setFundoEscuro();
  }

  initializeApp() {
    this.plat = this.platform.platforms();
    let android: boolean = false;
    for (let i = 0; i < this.plat.length; i++) {
      const element = this.plat[i];
      
      if(element == 'android' || element == 'iphone' || element == 'ipad'){
        android = true;
      }            
            
    }
    this.platform.ready().then(() => {
      if(android == true){
        this.criarBD();
      }
      this.network.onDisconnect().subscribe(async () => {
        this.loader.showError('Ops', 'Você esta sem conexão com a internet');        
      });
      this.network.onConnect().subscribe(() => {
        this.loader.presentToast('Ótimo! O celular esta conectado à internet');
      });
    });
  }

  checkLoginStatus() {
    return this.userData.isLoggedIn().then(loggedIn => {
      return this.updateLoggedInStatus(loggedIn);
    });
  }

  returnName(){
    return this.userData.getUsername().then(nome => {      
      this.nome = nome;
    });
  }

  returnFundoEscuro(){
    return this.userData.getFundoEscuro().then(fundo => {      
      this.dark = fundo;
    });
  }
  
  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
  }

  returnDataExpira(){
    return this.userData.getDataExpira().then(dataExpira => {
      // console.log(dataExpira);
      // let dia: any = dataExpira.getDate();
      // let mes: any = dataExpira.getMonth() + 1;
      //this.expira = this.padLeft(dia, '0', 2) + '/' + this.padLeft(mes, '0', 2);
    });
  }

  setFundoEscuro(){
    return this.userData.setFundoEscuroTrue(this.dark).then(fundo => {      
      this.dark = fundo;
    });
  }

  updateLoggedInStatus(loggedIn: boolean) {
    setTimeout(() => {
      this.loggedIn = loggedIn;
    }, 300);
  }

  listenForLoginEvents() {
    window.addEventListener('user:login', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:signup', () => {
      this.updateLoggedInStatus(true);
    });

    window.addEventListener('user:logout', () => {
      this.updateLoggedInStatus(false);
    });
  }

  logout() {
    this.userData.logout().then(() => {
      return this.router.navigateByUrl('/login');
    });
  }

  suporte(){
    // window.open("https://wa.me/556281888263?text=Olá, estou com dúvidas...", "_blank");
    this.socialSharing.shareViaWhatsAppToPhone('+556281888263', 'Message via WhatsApp', null, null).then((e) =>{
      console.log(e)
    }).catch((e) => {
      window.open("https://wa.me/556281888263?text=Olá, estou com dúvidas...", "_blank");
    });    
  }

  openTutorial() {
    this.menu.enable(false);
    this.storage.set('ion_did_tutorial', false);
    this.router.navigateByUrl('/tutorial');
  }

}

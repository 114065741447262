<ion-header>
  <ion-toolbar>    
    <ion-title>Detalhe da avaliação</ion-title>
    <ion-buttons slot="start">
      <ion-button (click)="voltar()">
        <ion-icon name="chevron-back-outline"></ion-icon>    
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-list *ngFor="let item of vendas">
    <ion-item-divider color="secondary">
      <ion-label>
        {{ item.VENDEDOR }}
      </ion-label>
    </ion-item-divider>
    <ion-item *ngFor="let k of item.DADOS" detail>
      <ion-avatar slot="start">
        <img *ngIf="k.VALOR == 'RUIM'" src="assets/imgs/ruim.png">
        <img *ngIf="k.VALOR == 'REGULAR'" src="assets/imgs/regular.png">
        <img *ngIf="k.VALOR == 'BOM'" src="assets/imgs/bom.png">
        <img *ngIf="k.VALOR == 'OTIMO'" src="assets/imgs/otimo.png">
      </ion-avatar>
      <ion-label (click)="chamarNoWhats(k.TELEFONE)">    
        <h2>Cliente: {{ k.CLIENTE }}</h2>
        <h3>Data da venda: {{ k.DATA_VENDA }}</h3>    
        <h3>Data da avaliação: {{ k.DATA_AVALIACAO }}</h3>    
        <h3>Telefone: {{ k.TELEFONE }}</h3>
        <p class="ion-text-wrap"><strong>{{ k.RETORNO }}</strong></p>
      </ion-label>
      
    </ion-item>
  </ion-list>
</ion-content>
<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-back-button defaultHref="app/tabs/schedule/cadastrar-item-mercado-livre" (click)="voltar()"></ion-back-button>
    </ion-buttons>
    <ion-title>Escolha as opções</ion-title>    
  </ion-toolbar>
</ion-header>
<ion-content>  
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Escolha as opções</ion-title>
    </ion-toolbar>    
  </ion-header>

  <ion-list>
    <ion-card *ngIf="parametro == 'atributos' || parametro == 'descricao' ">
      <ion-card-header>
        <ion-card-subtitle>{{ default_unit }}</ion-card-subtitle>      
        <ion-card-title>{{ nomeParametro }}</ion-card-title>      
      </ion-card-header>  
      <ion-item>
        <ion-label position="stacked"></ion-label>
        <ion-textarea rows="6" cols="20" [(ngModel)]="val" [placeholder]="place"></ion-textarea>
      </ion-item>
    </ion-card>

  </ion-list>
  <ion-list>
    <ion-item detail *ngFor="let it of atribut" (click)="escolherAtributo(it)">
      <ion-label>{{ it.name }}</ion-label>
    </ion-item>
  </ion-list>
  <ion-list>    
    <ion-item detail *ngFor="let item of categorias" (click)="escolherCategoria(item)">
      <ion-label>{{ item.name }}</ion-label>
    </ion-item>  
  </ion-list>
  </ion-content>


  <ion-footer>
    <ion-toolbar>      
      <ion-button expand="full" (click)="confirmarEscolhe()">Confirmar</ion-button>
    </ion-toolbar>
  </ion-footer>
<ion-header>
  <ion-toolbar color="danger">
    <ion-title>Parâmetros</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
  <ion-header collapse="condense">
    <ion-toolbar>
      <ion-title size="large">Parâmetros</ion-title>
    </ion-toolbar>    
  </ion-header>
  
  <ion-list>
    <ion-item detail *ngIf="lojas" (click)="buscarLojas()">
      <ion-label>Lojas: {{ parametro.lj }}</ion-label>      
    </ion-item>
    <ion-item detail *ngIf="artigo" (click)="buscarArtigos()">
      <ion-label>Artigo</ion-label>
      <ion-chip outline="true" color="primary" *ngFor="let item of parametro.filtroArtigos">
        <ion-label color="dark">{{ item }}</ion-label>
      </ion-chip>      
    </ion-item>
    <ion-item detail *ngIf="marca" (click)="buscarMarcasServidor()">
      <ion-label>Marca</ion-label>
      <ion-chip outline="true" color="primary" *ngFor="let item of parametro.filtroMarcas">
        <ion-label color="dark">{{ item }}</ion-label>
      </ion-chip>      
    </ion-item>
    <ion-item detail *ngIf="fornecedor">
      <ion-label>Fornecedor</ion-label>
    </ion-item>
    <ion-item detail *ngIf="grupo" (click)="buscarGruposServidor()">
      <ion-label>Grupo</ion-label>
      <ion-chip outline="true" color="primary" *ngFor="let item of parametro.filtroGrupos">
        <ion-label color="dark">{{ item }}</ion-label>
      </ion-chip>      
    </ion-item>
    <ion-item detail *ngIf="colecao">
      <ion-label>Coleção</ion-label>
    </ion-item>
    <ion-item detail *ngIf="genero">
      <ion-label>Gênero</ion-label>
    </ion-item>

    <!--
    <ion-item detail *ngIf="dataInicial">
      <ion-label>Data inicial</ion-label>
      <ion-datetime cancelText="Cancelar" doneText="Escolher" displayFormat="DD/MM/YYYY" 
      [(ngModel)]="parametro.dataInicial"></ion-datetime>
    </ion-item>
    -->
    
    <ion-item button="true" id="open-date-input-dataInicial"  detail *ngIf="dataInicial">
      <ion-label>Data Inicial</ion-label>      
      <ion-text slot="end">{{ parametro.dataInicial }}</ion-text>
      <ion-popover size="cover" trigger="open-date-input-dataInicial" show-backdrop="false">
        <ng-template>
          <ion-content>
          <ion-datetime cancelText="Cancelar" doneText="Confirmar" showDefaultButtons="true" presentation="date" #dataInicial 
          (ionChange)="escolherDataInicial(dataInicial.value)" 
          ></ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>
    
    <!--
    <ion-item detail *ngIf="dataFinal">
      <ion-label>Data Final</ion-label>
      <ion-datetime cancelText="Cancelar" doneText="Escolher" displayFormat="DD/MM/YYYY" [(ngModel)]="parametro.dataFinal"></ion-datetime>
    </ion-item>    
    -->
    <ion-item button="true" id="open-date-input-dataFinal"  detail *ngIf="dataFinal">
      <ion-label>Data Final</ion-label>      
      <ion-text slot="end">{{ parametro.dataFinal }}</ion-text>
      
      <ion-popover size="cover" trigger="open-date-input-dataFinal" show-backdrop="false">
        <ng-template>
          <ion-content>
          <ion-datetime cancelText="Cancelar" doneText="Confirmar" showDefaultButtons="true" 
          presentation="date" #dataFinal (ionChange)="escolherDataFinal(dataFinal.value)" 
            ></ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>
    <!--    
    <ion-item button="true" id="open-date-input-dia"  detail *ngIf="data">
      <ion-label>Data</ion-label>      
      <ion-text slot="end">{{ data }}</ion-text>
      <ion-popover trigger="open-date-input-dia" show-backdrop="false">
        <ng-template>
          <ion-datetime #dataUnica (ionChange)="escolherData(dataUnica.value)"></ion-datetime>
        </ng-template>
      </ion-popover>
    </ion-item>
    
    <ion-item detail *ngIf="dataMes">
      <ion-label>Mês</ion-label>
      <ion-datetime cancelText="Cancelar" doneText="Escolher" displayFormat="MM/YYYY" pickerFormat="MM YYYY"       
      [(ngModel)]="parametro.dataMes"></ion-datetime>
    </ion-item>
    -->
    <ion-item button="true" id="open-date-input-mes" *ngIf="dataMes">
      <ion-label>Mês/Ano</ion-label>
      <ion-text slot="end">{{ parametro.dataMes }}</ion-text>
      <ion-popover size="cover" trigger="open-date-input-mes" show-backdrop="false">
        <ng-template>
          <ion-content>
            <ion-datetime
              #popoverDatetime
              cancelText="Cancelar" doneText="Confirmar" showDefaultButtons="true" 
              presentation="month-year"
              (ionChange)="mesAno(popoverDatetime.value)"              
            ></ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>
    <!--
    <ion-item detail *ngIf="data1">
      <ion-label>1° Data:</ion-label>      
      <ion-input type="date" [(ngModel)]="parametro.data1"></ion-input>
    </ion-item>
    -->
    <ion-item button="true" id="open-date-input-dia1"  detail *ngIf="data1">
      <ion-label>1° Data:</ion-label>      
      <ion-text slot="end">{{ parametro.data1 }}</ion-text>
      <ion-popover size="cover" trigger="open-date-input-dia1" show-backdrop="false">
        <ng-template>
          <ion-content>
            <ion-datetime cancelText="Cancelar" doneText="Confirmar" showDefaultButtons="true" presentation="date" #dataUnica1 (ionChange)="escolherData1(dataUnica1.value)" 
            ></ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>
    <!--
    <ion-item detail *ngIf="data2">
      <ion-label>2° Data:</ion-label>      
      <ion-input type="date" [(ngModel)]="parametro.data2"></ion-input>
    </ion-item>
    -->
    <ion-item button="true" id="open-date-input-dia2"  detail *ngIf="data2">
      <ion-label>2° Data:</ion-label>      
      <ion-text slot="end">{{ parametro.data2 }}</ion-text>
      <ion-popover size="cover" trigger="open-date-input-dia2" show-backdrop="false">
        <ng-template>
          <ion-content>
            <ion-datetime cancelText="Cancelar" doneText="Confirmar" showDefaultButtons="true"  presentation="date" #dataUnica2 (ionChange)="escolherData2(dataUnica2.value)" 
            ></ion-datetime>
          </ion-content>
        </ng-template>
      </ion-popover>
    </ion-item>
  </ion-list>
  
  <ion-list *ngIf="parametro_retroativo == true ">
    <ion-radio-group [(ngModel)]="parametro.parametro">
      <ion-list-header>
        <ion-label>
          Comparar período do:
        </ion-label>
      </ion-list-header>
      <ion-item>
        <ion-label>ano anterior</ion-label>
        <ion-radio value="anual" checked></ion-radio>
      </ion-item>
      <ion-item>
        <ion-label>mes anterior</ion-label>
        <ion-radio value="mensal"></ion-radio>
      </ion-item>
    </ion-radio-group>
  </ion-list>
  <ion-item *ngIf="somenteValores == true ">
    <ion-label>Somente valores</ion-label>
    <ion-checkbox [(ngModel)]="parametro.somenteValores" checked="false"></ion-checkbox>
  </ion-item>

  <ion-item *ngIf="valoresVencimento == true ">
    <ion-label>Valores por vencimento</ion-label>
    <ion-checkbox [(ngModel)]="parametro.valoresVencimento" checked="false"></ion-checkbox>
  </ion-item>

</ion-content>
<ion-footer class="ion-no-border">
  <ion-toolbar>
    <ion-button color="primary" expand="full" (click)="closeModal()">Buscar</ion-button>
  </ion-toolbar>
</ion-footer>
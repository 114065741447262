import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class FinanceiroLojasService {
  public Parametro = {
    id: '',
    mesAno: '',
    loja: '',
    dataInicial: '',
    dataFinal: '',
    parametro: '',
    data_final: {
      year: '',
      month: '',
      day: ''
    },
    data_inicial : {
      year: '',
      month: '',
      day: ''
    },
    cod_usr: '',
    cc: ''
  }
  constructor(public auth: AuthServiceService, private http: HttpClient) { }

  financeiroLojas(credentials) {
    //credentials.push(this.auth.Id);
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {

      this.Parametro.data_inicial.year = credentials['dataInicial'].substr(0,4);
      this.Parametro.data_inicial.month = credentials['dataInicial'].substr(5,2);
      this.Parametro.data_inicial.day = credentials['dataInicial'].substr(8,2);
      
      this.Parametro.data_final.year = credentials['dataFinal'].substr(0,4);
      this.Parametro.data_final.month = credentials['dataFinal'].substr(5,2);
      this.Parametro.data_final.day = credentials['dataFinal'].substr(8,2);
      
      this.Parametro.id = this.auth.Id;
      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarContasAbertasp', this.Parametro)
        .subscribe((result: any) => {
          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  financeiroLojasDetalhe(credentials) {
    //credentials.push(this.auth.Id);
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;
      this.Parametro.loja = credentials['loja'];
      this.Parametro.dataInicial = credentials['dataInicial'];
      this.Parametro.dataFinal = credentials['dataFinal'];
      this.Parametro.parametro = credentials['parametro'];
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/buscarDetalheContasApagarp', this.Parametro)
        .subscribe((result: any) => {
          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  financeiroExtrato() {
    //credentials.push(this.auth.Id);
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultaExtrato', this.Parametro)
        .subscribe((result: any) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  baixarAutomatica(arr, cc) {
    let array = [];    
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;      
      this.Parametro.cod_usr = this.auth.Usuario.codigo;
      this.Parametro.cc = cc;
      array.push(this.Parametro);
      array.push(arr);            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/baixaAutomaticaTitulos', array)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  //consultarCondicaoPagto
  consultarCondicaoPagto() {        
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;      
      this.Parametro.cod_usr = this.auth.Usuario.codigo;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultarCondicaoPagto', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  salvarCondicaoPagto(cond) {        
    let arr = [];
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;      
      arr.push(this.Parametro);
      arr.push(cond);      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/atualizarCondicao', arr)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  consultarDocumentosPagto() {        
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;            
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultaTipoDeDocumento', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  consultarRecebimentosPorDocumento(param) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      this.Parametro.id = this.auth.Id;        
      this.Parametro.parametro = param;      
      console.log(this.Parametro);
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultarRecebimentosPorPagamento', this.Parametro)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  criarContasPagar(param) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      param.id = this.auth.Id;
      param.usuario = this.auth.Usuario.codigo;
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/salvarContasAPagar', param)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  consultarContasForn(param) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      param.id = this.auth.Id;
      param.usuario = this.auth.Usuario.codigo;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/consultarContasFornecedor', param)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  conciliadorCartoes(param) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {
      param.id = this.auth.Id;
      param.usuario = this.auth.Usuario.codigo;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/conciliadorRecebimentos', param)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }

  baixarContasReceber(param) {
    if(this.auth.URL == '' || this.auth.Id == undefined){
      //const root = this.app.getRootNav();
      //root.setRoot('LoginPage');
      //return new Promise(null);
    } else {      
      param.usuario = this.auth.Usuario.codigo;      
      return new Promise((resolve, reject) => {
        this.http.post(this.auth.URL + '/baixarContasReceber', param)
        .subscribe((result: any) => {          
          resolve(result);
        },
        (error) => {
          reject(error);
        });
      });
    }
  }
}

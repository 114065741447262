

<ion-header>
  <ion-toolbar color="danger">
    <ion-title>{{ tela }}</ion-title>
  </ion-toolbar>
  <ion-searchbar [(ngModel)]="searchbar" [showCancelButton]="shouldShowCancel" cancelButtonText="Cancelar" placeholder="Buscar" 
  (ionInput)="getItems($event)" type="text">
</ion-searchbar>
</ion-header>

<ion-content padding>

  <div *ngIf="cadastrar == true && tela != 'ncm' ">
    <ion-button [disabled]=desabilitarBotao (click)="cadastrarGenerico()">Cadastrar {{ tela }}</ion-button>
  </div>
  <div *ngIf="coresEscolhidas != []">
    <p *ngFor="let item of coresEscolhidas">
      <ion-button small color="danger" (click)="apagarCor(item)"><ion-icon name="trash"></ion-icon></ion-button>Cores: {{ item.DESCRICAO }}
    </p>
  </div>
  <ion-list>
    <ion-radio-group [(ngModel)]="codigoEscolhido">
      <ion-item *ngFor="let item of it">
        <ion-label>{{ item.DESCRICAO }}</ion-label>            
        <ion-radio slot="start" *ngIf="tela != 'cores' " (ionBlur)=marcarParametro(item)></ion-radio>
        <ion-checkbox *ngIf="tela == 'cores' " [checked]="item.CHECK" (ionChange)="marcarCores(item)" >
        </ion-checkbox>      
      </ion-item>
    </ion-radio-group>
  </ion-list>  
</ion-content>
<ion-footer>
  <ion-toolbar>
    <ion-button (click)="escolher()" expand="full" color="primary">Salvar/Escolher</ion-button>    
  </ion-toolbar>
</ion-footer>
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';


@Injectable({
  providedIn: 'root'
})
export class UserData {
  favorites: string[] = [];
  HAS_LOGGED_IN = 'hasLoggedIn';
  HAS_SEEN_TUTORIAL = 'hasSeenTutorial';

  constructor(
    public storage: Storage
  ) { }

  hasFavorite(sessionName: string): boolean {
    return (this.favorites.indexOf(sessionName) > -1);
  }

  addFavorite(sessionName: string): void {
    this.favorites.push(sessionName);
  }

  removeFavorite(sessionName: string): void {
    const index = this.favorites.indexOf(sessionName);
    if (index > -1) {
      this.favorites.splice(index, 1);
    }
  }

  login(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:login'));
    });
  }

  signup(username: string): Promise<any> {
    return this.storage.set(this.HAS_LOGGED_IN, true).then(() => {
      this.setUsername(username);
      return window.dispatchEvent(new CustomEvent('user:signup'));
    });
  }

  logout(): Promise<any> {
    return this.storage.remove(this.HAS_LOGGED_IN).then(() => {
      return this.storage.remove('username');
    }).then(() => {
      window.dispatchEvent(new CustomEvent('user:logout'));
    });
  }

  setUsername(username: string): Promise<any> {
    return this.storage.set('username', username);
  }

  setFundoEscuroTrue(fundo: boolean): Promise<any> {
    return this.storage.set('fundoescuro', fundo);
  }

  getFundoEscuro(): Promise<boolean> {
    return this.storage.get('fundoescuro').then((value) => {
      return value;
    });
  }

  setDataExpira(): Promise<any> {
    var dataExpira = new Date(); //dataExpira      
    dataExpira.setDate(dataExpira.getDate() + 7);    
    return this.storage.set('dataexpira', dataExpira);
  }

  getDataExpira(): Promise<Date> {    
    return this.storage.get('dataexpira').then((value) => {
      return value;
    });
  }

  getUsername(): Promise<string> {
    return this.storage.get('username').then((value) => {
      return value;
    });
  }
  

  isLoggedIn(): Promise<boolean> {
    return this.storage.get(this.HAS_LOGGED_IN).then((value) => {
      return value === true;
    });
  }

  checkHasSeenTutorial(): Promise<string> {
    return this.storage.get(this.HAS_SEEN_TUTORIAL).then((value) => {
      return value;
    });
  }

  getPermissoes(): Promise<string> {
    return this.storage.get('ID').then((value) => {      
      return value;
    });
  }

  formatarChaveDeAcessoNfe(chave): Promise<string>{
    if(chave.length !== 44) return chave;
    else{
      return chave.split("").reduceRight(function(elemento, anterior){
        var temp = anterior + elemento;
          if(temp.replace(/\s/g, "").length % 4 === 0) return " " + temp;
          else return temp;
      });
    }
  }

  getServidores(): Promise<string>{
    return this.storage.get('ID').then((val) => {
      // console.log(val);      
      if(val != null){
        for (let i = 0; i < val.length; i++) {
          const element = val[i];          
          /*this.servidores.push({
            "IDSERVIDOR" : element.ID_,
            "EMPRESA": element.EMPRESA,
            "NOME": element.nome,
            "SENHA": element.senha
          });*/
        }
      }
      return val;
    })
  }
}

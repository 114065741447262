import { Injectable } from '@angular/core';
import { SQLiteDatabaseConfig, SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';
import { Platform, ToastController } from '@ionic/angular';


@Injectable({
  providedIn: 'root'
})
export class DbService {
  
  constructor(private sqlite: SQLite, private device: Platform, public toastCtrl: ToastController) { 
      
  }
  
  public getDB(){
    // alert(this.device.platform);
    var db = null;    
    if(this.device.is('ios')) {
      //this.alert();
      return this.sqlite.create({
        name: '_winsae_mobile.db',
        iosDatabaseLocation: 'Documents'
      });
    } else {
      //this.alert();
      return this.sqlite.create({
        name: 'winsaeApp.db',
        location: 'default'
      });
    }
  }

  public createDatabase() {
    return this.getDB()
      .then((db: SQLiteObject) => {
        this.createTables(db);
      })
      .catch(e => console.log(e));
  }

  /**
   * Criando as tabelas no banco de dados
   * @param db
   */
  private createTables(db: SQLiteObject) {
    // Criando as tabelas
    // alert("Criando tabelas");
    db.sqlBatch([
      ['CREATE TABLE IF NOT EXISTS usuarios (idusuario text primary key, nome text, senha text, desconto text, loja text, datainclusao text, vendedor text, idservidor text)'],
      ['CREATE TABLE IF NOT EXISTS servidores (idservidor INTEGER PRIMARY KEY AUTOINCREMENT, apelido text, datainclusao text, urlservidor text, porta text, path text, email text, senha text, empresa text)'],      
    ])
    .then((e) => {
      // alert(e);
    }).catch((e) => {
      alert('Erro ao criar as tabelas: '+ e)
    });
  }

  /**
   * Criando as tabelas no banco de dados
   * @param db
   */
  public consultarServidores(){
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {        
        // sql = ' select idcliente as CODCLIE, nomecliente as NOME, cnpjcliente as CGC, cpfcliente AS CPF, cepcliente AS CEP, ufcliente AS UF, cidadecliente AS CIDA, datainclusao, whatsapp AS TELE, endereco AS ENDE, bairro AS BAIRRO, bloqueado as BLOQUEADO, COD_UF, COD_CIDA from cliente where nomecliente like "'+nome+'%" or cnpjcliente = "'+nome+'" or cpfcliente = "'+nome+'" group by idcliente, nomecliente, cnpjcliente, cpfcliente, cepcliente, ufcliente, cidadecliente, datainclusao, whatsapp, endereco, bairro, bloqueado, COD_UF, COD_CIDA order by nomecliente ';
        var sql = "";        
        sql = ' select IDSERVIDOR, APELIDO as CHAVEDEACESSO, DATAINCLUSAO, URLSERVIDOR, PORTA, PATH, EMAIL, EMPRESA as EMPRESA from servidores ';                
        db.executeSql(sql, []).then((data: any) => {   
            let servidores = [];
            // alert(data.rows);
            if(data.rows.length > 0) {
                for(let i = 0; i < data.rows.length; i++) {
                  servidores.push(data.rows.item(i));
                }
            }              
            resolve(servidores);
        }).catch((e) => alert("1_"+e.message));
      })
      .catch((e) => alert("2_"+e.message));        
    });
  }
  

  public insertServidor(apelido, urlServidor, porta, path, email, senha, empresa) {
    this.remove(apelido);
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = ' insert into servidores (apelido, datainclusao, urlservidor, porta, path, email, senha, empresa) values (?, CURRENT_DATE, ?, ?, ?, ?, ?, ?) ';
        let data = [apelido, urlServidor, porta, path, email, senha, empresa];
        return db.executeSql(sql, data).then(() => console.log("Inseriu"))
          .catch((e) => console.error(e)), this.msgToast("A informação foi salva com sucesso!");
        })
      .catch((e) => console.error(e));      
  }

  public removeApelido(apelido) {
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'delete from servidores where apelido = ?';
        let data = [apelido];
        return db.executeSql(sql, data).then(() => console.log('Executed SQL'))
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  public remove(id) {
    return this.getDB()
      .then((db: SQLiteObject) => {
        let sql = 'delete from servidores where idservidor = ?';
        let data = [id];
        return db.executeSql(sql, data).then(() => console.log('Executed SQL'))
          .catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));
  }

  public insertUsuario(idusuario, nome, senha, desconto, loja, vendedor, idservidor) {
    this.deleteUsuarios(idusuario, idservidor);
    return this.getDB()
      .then((db: SQLiteObject) => {
        // let sql = ' insert into usuarios (idusuario, nome, senha, desconto, loja, datainclusao, vendedor, idservidor) values (?, ?, ?, ?, ?, CURRENT_DATE, ?, ?) ';
        let sql = ' INSERT OR REPLACE into usuarios (idusuario, nome, senha, desconto, loja, datainclusao, vendedor, idservidor) values (?, ?, ?, ?, ?, CURRENT_DATE, ?, ?) ';         
        let data = [idusuario, nome, senha, desconto, loja, vendedor, idservidor];        
        return db.executeSql(sql, data).then(() => console.log('Executed SQL'))
          .catch((e) => console.error(e));
        })
      .catch((e) => console.error(e));      
  } 

  public deleteUsuarios(idusuario, idservidor) {    
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {
        let sql = ' DELETE FROM usuarios where idusuario = ? and idservidor = ?';
        db.executeSql(sql, [idusuario, idservidor]).then((data: any) => {
        }).catch((e) => console.error(e));
      })
      .catch((e) => console.error(e));        
    });
}

  public getUsuarios(idservidor) {    
    return new Promise((resolve, reject) => {
      this.getDB().then((db: SQLiteObject) => {
        let sql = ' SELECT idusuario, nome, senha, desconto, idservidor FROM usuarios where idservidor = ? ';
        db.executeSql(sql, [idservidor]).then((data: any) => {            
          let usuario = [];
            if(data.rows.length > 0) {
                for(let i = 0; i < data.rows.length; i++) {                  
                  usuario.push({
                    id: data.rows.item(i).idusuario,
                    nome: data.rows.item(i).nome,
                    senha: data.rows.item(i).senha,
                    desconto: data.rows.item(i).desconto,
                    idservidor: data.rows.item(i).idservidor
                  });
                }
            }                        
            resolve(usuario);                        
        }).catch((e) => console.error(e));
      })

      .catch((e) => console.error(e));        
    });
  }

  async msgToast(mensagem) {
    const toast = await this.toastCtrl.create({
      message: mensagem,
      duration: 3000,
      position: 'bottom'
    });
    toast.present();
  }

  
}
